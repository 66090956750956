<template>
  <div>
    <div v-if="step == 1" class="calendar">

      <div class="title">Select a Timezone</div>
      <div class="mb-32">
        <input list="cars" v-model="timezone" class="ant-input ant-input-lg">
        <datalist id="cars">
          <option :value="timezone" v-for="timezone in timezones" :key="timezone"/>
        </datalist>
      </div>

      <div class="title">Select a Day</div>
      <div class="head">
        <div class="month">{{ label }}</div>
        <div>
          <button title="Go to previous month" @click="prevMonth">&lt;</button>
        </div>
        <div>
          <button title="Go to next month" @click="nextMonth">&gt;</button>
        </div>
      </div>
      <div class="grid">
        <div class="cell_width" v-for="weekday in weekdays" :key="weekday">
          <div class="hcell">
            {{ weekday }}
          </div>
        </div>
        <div class="cell_width" v-for="day in days" :key="day.value">
          <div v-if="!day.empty"
               class="cell"
               :class="{
            current:day.isCurrent,
            disabled: day.isDisabled,
            selected:day.isSelected
          }"
               @click="selectDay(day)">
            {{ day.value }}
          </div>
          <div v-else class="cell empty">&nbsp;</div>
        </div>
      </div>
    </div>

    <div v-if="step == 2" class="timeslots">
      <div class="title">Booked Day: {{ bookedDay }}</div>
      <div class="title">Select a Time</div>
      <div class="grid mb-32">
        <div class="cell" v-for="time in times" :key="time" @click="selectTime(time)">{{ time }}</div>
      </div>
      <ui-button @click="step = 1">Back to select day</ui-button>
    </div>

    <div v-if="step == 3" class="timeslots">
      <div class="title">Succes!</div>
      <div class="mb-32">
        Booked on {{ bookedDay }} at {{ bookedTime }}<br>
        Timezone: {{ timezone }}
      </div>
      <ui-button @click="step = 1">Rebooooook</ui-button>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import UiButton from '../components/ui/UiButton';

dayjs.extend(weekday)
dayjs.extend(weekOfYear)

export default {
  name: 'calendar',
  components: { UiButton },
  data() {
    const year = dayjs().format('YYYY');
    const month = dayjs().format('M');

    const offset = new Date().getTimezoneOffset();
    let tzh = (offset / 60) * -1;
    if (tzh < 0) tzh = `UTC ${tzh}`;
    else tzh = `UTC +${tzh}`;

// eslint-disable-next-line global-require
    let tzl = require('@/utils/timezones.json').map((tz) => `${tz.abbreviation} ${tz.offset}`);
    tzl = [...new Set(tzl)];

    console.log('user:', tzh, tzl.find((tz) => tz.endsWith(tzh)))
    return {
      step: 1,
      weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      startFrom: dayjs(`${year}-${month}-01`).weekday(),
      year,
      month,
      selectedDate: dayjs(),
      bookedDay: null,
      bookedTime: null,

      timezones: tzl,
      timezone: tzl.find((tz) => tz.endsWith(tzh)),
    }
  },
  computed: {
    label() {
      return dayjs(`${this.year}-${this.month}-01`).format('MMM YYYY');
    },
    numberOfDaysInMonth() {
      return dayjs(`${this.year}-${this.month}-01`).daysInMonth();
    },
    days() {
      const days = [];
      for (let i = 1; i < this.startFrom; i += 1) {
        days.push({
          empty: true
        });
      }

      const d = dayjs().day();
      const m = dayjs().month();
      // const y = dayjs().year();

      for (let i = 0; i < this.numberOfDaysInMonth; i += 1) {
        const my = dayjs(`${this.year}-${this.month}-${i + 1}`);
        days.push({
          value: i + 1,
          // eslint-disable-next-line eqeqeq
          isCurrent: (d == i + 1) && this.month == m + 1,
          isDisabled: my.weekday() !== 5
        });
      }
      return days;
    },
    times() {
      return ['19:00', '14:00', '11:45']
    }
  },
  methods: {
    prevMonth() {
      const my = dayjs(`${this.year}-${this.month}-01`).add(-1, 'month');
      this.startFrom = my.weekday();
      this.year = my.format('YYYY');
      this.month = my.format('M');
    },
    nextMonth() {
      const my = dayjs(`${this.year}-${this.month}-01`).add(1, 'month');
      this.startFrom = my.weekday();
      this.year = my.format('YYYY');
      this.month = my.format('M');
    },
    selectDay(day) {
      console.log(day);
      this.bookedDay = dayjs(`${this.year}-${this.month}-${day.value}`).format('DD-MMM-YYYY')
      this.step = 2;
    },
    selectTime(time) {
      console.log(time);
      this.bookedTime = time
      this.step = 3;
    }
  }
}
</script>

<style scoped lang="scss">
.calendar {

  // outline: 1px dashed red;

  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .head {
    display: flex;
    margin-bottom: 8px;

    height: 24px;
    font-size: 16px;

    .month {
      flex-grow: 1;
    }

    button {
      border: none;
      background: transparent;
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
  }

  .cell_width {
    width: 14.2%;

  }

  .hcell {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 6px;
    margin-bottom: 6px;
  }

  .cell {
    font-size: 14px;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 1px solid gray;
  }

  .empty {
    border: 1px solid transparent;
  }

  .cell:hover {
    color: #000000;
    background: rgba(24, 144, 255, 0.16);
  }
}

.timeslots {

  // outline: 1px dashed red;

  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .cell {
    width: 100px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 12px;

    margin-right: 4px;
    margin-bottom: 9px;

    //color: #808080;
    //background: #dcdcdc;

    border: 1px solid gray;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .cell:hover {
    color: #000000;
    background: rgba(24, 144, 255, 0.16);
  }

}

.current {
  color: #ffffff;
  background: #ff8080;
  border: 1px solid #ff8080 !important;
}

.selected {
  color: #ffffff;
  background: #58b1ff;
}

.disabled {
  color: #808080;
  background: #dcdcdc;
}

</style>
